var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"match-height"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-bottom"},[_c('h4',{staticClass:"card-title"},[_vm._v("Tasks ("+_vm._s(_vm.totalCount)+")")])]),_c('div',{staticClass:"card-datatable table-responsive"},[_c('table',{staticClass:"datatables-ajax table "},[_c('thead',[_c('tr',[_c('th',[_vm._v("Customer")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Platform")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Action")]),_c('th',[_vm._v("Status")])])]),_c('tfoot',[_c('tr',[_c('th',[_vm._v("Customer")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Platform")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Action")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.services),function(service,index){return _c('tr',{key:index},[_c('td',[_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(index + 1)+". ")]),_c('a',{attrs:{"href":service.link,"target":"_blank"}},[_vm._v(_vm._s(service.created_by))])]),_c('td',[_vm._v(" "+_vm._s(service.title)+" "),_c('br'),_c('p',{staticClass:"text-primary m-0"},[_c('a',{attrs:{"href":service.link,"target":"_blank"}},[_vm._v("View Task")])])]),_c('td',[_vm._v(_vm._s(service.category.name))]),_c('td',[_vm._v(_vm._s(service.social_media))]),_c('td',[_vm._v("N"+_vm._s(service.amount))]),_c('td',[_vm._v(_vm._s(service.action))]),_c('td',{staticClass:"d-flex align-items-center justify-content-between"},[_vm._v(" "+_vm._s(service.status)+" "),_c('b-dropdown',{staticClass:"p-0",attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
                                                service.status.toLowerCase() !==
                                                    'approved'
                                            )?_c('b-dropdown-item',{staticClass:"text-primary",on:{"click":function($event){return _vm.actionConfirmation(
                                                    service.id,
                                                    'approve'
                                                )}}},[_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"CheckCircleIcon"}}),_c('span',{staticClass:"align-middle ml-50 text-success"},[_vm._v("Approve")])],1):_vm._e(),(
                                                service.status.toLowerCase() ===
                                                    'pending'
                                            )?_c('b-dropdown-item',{staticClass:"text-primary",on:{"click":function($event){return _vm.actionConfirmation(
                                                    service.id,
                                                    'rejected'
                                                )}}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"CheckCircleIcon"}}),_c('span',{staticClass:"align-middle ml-50 text-primary"},[_vm._v("Reject")])],1):_vm._e(),(
                                                service.status.toLowerCase() ===
                                                    'approved'
                                            )?_c('b-dropdown-item',{staticClass:"text-primary",on:{"click":function($event){return _vm.actionConfirmation(
                                                    service.id,
                                                    'rejected'
                                                )}}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"XCircleIcon"}}),_c('span',{staticClass:"align-middle ml-50 text-primary"},[_vm._v("Unpublish")])],1):_vm._e(),_c('b-dropdown-item',{staticClass:"text-danger",on:{"click":function($event){return _vm.actionConfirmation(
                                                    service.id,
                                                    'delete'
                                                )}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50 text-danger"},[_vm._v("Delete")])],1)],1)],1)])}),0)])])])])]),_c('div',{staticClass:"mx-2 mb-2"},[_c('nav',{attrs:{"aria-label":"Page navigation"}},[_c('ul',{staticClass:"pagination"},_vm._l((_vm.links),function(link){return _c('li',{key:link.id,class:link.active ? 'page-item active' : 'page-item'},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:;"},domProps:{"innerHTML":_vm._s(link.label)},on:{"click":function($event){return _vm.paginate(link.url)}}})])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }